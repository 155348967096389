import React, { useEffect } from 'react';
import Hero from './hero.component';
import About from './about.component';
import Price from './price.component';
import Calendar from './calendar.component';
import anime from 'animejs';
import { setActive } from '../utils/handyFunctions';
import Slogan from './slogan.component';
import Why from './why.component';
import Branches from './branches.component';

const Home = ({loaded, isLoading}) => {
    useEffect(() => {
        anime({
          targets: '.next-section > path',
          easing: 'easeInOutQuad',
          translateY: 2,
          loop: true,
          direction: 'alternate',
          duration: 500
        });
        anime({
          targets: '.next-section',
          easing: 'easeInOutQuad',
          scale: 1.1,
          loop: true,
          direction: 'alternate',
          duration: 1000
        });
        const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        document.querySelectorAll('.next-section, nav > ul > li, [type="button"], [data-target]').forEach(el => {
          el.addEventListener('click', e => {
            if (e.target.dataset.target) {
              const target = e.target.dataset.target;
              document.querySelectorAll('nav > ul > li, [type="button"]').forEach(element => {
                element.classList.remove('active');
              });
              console.dir(document.getElementById(target));
              document.querySelector('[data-target='+target+']').classList.add('active');
              if (document.getElementById(target)) {
                anime({
                  targets: scrollElement,
                  easing: 'easeInOutQuad',
                  scrollTop: document.getElementById(target).offsetTop - 100,
                  duration: 1500
                });
              } else {
                // window.scrollTo(0, 0);
              }
            }
          });
        });
        document.addEventListener('scroll', setActive);
        loaded(true);
        // eslint-disable-next-line
    }, []);

    return <>
        <Hero/>
        <Slogan/>
        <About/>
        <Branches/>
        <Why/>
        <Price/>
        <Calendar/>
    </>
}

export default Home;