import anime from 'animejs';

export const loadAllAssets = (name, url) => {
    /* write to localStorage */
    return ajaxResource(name, url);
}

export const ajaxResource = (assetName, path) => {
    return new Promise((resolve, reject) => {
        try {
            let request = new XMLHttpRequest();
            request.open("GET", path, true);
            request.onload = function (e) {
                if( request.status >= 200 && request.status < 400 ) {
                    let data = request.responseText;
                    localStorage.setItem(assetName, data);
                    resolve();
                } else reject('Bad request. request status: ' + request.status);
            }
            request.send();
        }
        catch (e) {
            reject(e);
        }
    });
};

export const activeOnScroll = () => {
    Array.from(document.querySelectorAll('section')).forEach((el, i) => {
        el.classList.remove('active');
    });
    if (window.scrollY >= document.getElementById('about').offsetTop - 300) {
        document.getElementById('about').classList.add('active');
      }
    if (window.scrollY >= document.getElementById('price').offsetTop - 300) {
        document.getElementById('about').classList.add('active');
    }
    if (window.scrollY >= document.getElementById('calendar').offsetTop - 300) {
        document.getElementById('about').classList.add('active');
    }
}
export const setActive = () => {
    const windowPosY = window.scrollY;
    if (document.getElementById('slogan')) {
        if (windowPosY >= document.getElementById('slogan').offsetTop - 300) {
            document.querySelector('#navigation').classList.add('has-background');
        } else {
            document.querySelector('#navigation').classList.remove('has-background');
        }
        document.querySelectorAll('section').forEach(section => {
            if (windowPosY >= section.offsetTop - 300) {
                if (section.id === 'slogan') {
                    document.querySelector('.go-up').classList.remove('visible');
                }
                if (section.id === 'about') {
                    document.querySelector('.go-up').classList.add('visible');
                }
                document.querySelectorAll('nav > ul > li, [type="button"]').forEach(element => {
                    element.classList.remove('active');
                    document.querySelector('[data-target="'+section.id+'"]').classList.add('active');
                });
            }
        });
    }
    // loadAllAssets('grass', grass).then(() => setLoading(false));
}

export const scrollingTop = () => {
    const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    anime({
        targets: scrollElement,
        easing: 'easeInOutQuad',
        scrollTop: 0,
        duration: 1500
    });
}
export const scrollToTop = () => {
    const windowPosY = window.scrollY;
    if (document.querySelector('#system')) {
        if (windowPosY >= document.querySelector('#system').offsetTop - 100) {
            document.querySelector('.go-up').classList.add('visible');
            document.querySelector('.go-up').addEventListener('click', scrollingTop);
        } else {
            document.querySelector('.go-up').classList.remove('visible');
            document.querySelector('.go-up').removeEventListener('click', scrollingTop);
        }
    }
}