import React, { useEffect } from 'react';
import anime from 'animejs';
import ice from '../assets/images/iceberg_blank.svg';
import logo from '../assets/images/logo.svg';
import boat from '../assets/images/boot.png';
import w1 from '../assets/images/w-1.png';
import w2 from '../assets/images/w-2.png';
import * as _ from 'lodash';
import { KeyboardArrowRight } from '@material-ui/icons';

const Slogan = () => {
    useEffect(() => {
        anime({
            targets: '#boat',
            easing: 'linear',
            translateX: ["-55vw", "60vw"],
            rotate: [
                {value: [-0.5, 0.5]},
                {value: [0.5, -0.5]},
                {value: [-0.5, 0.5]},
            ],
            loop: true,
            duration: 100000
        });
        anime({
            targets: '.cloud',
            easing: 'linear',
            translateX: ["-50vh", "100vh"],
            loop: true,
            duration: 200000
        });
        anime({
            targets: '.cloud-2',
            easing: 'linear',
            translateX: ["-50vh", "100vh"],
            loop: true,
            duration: 300000
        });
        anime({
            targets: '.bubble',
            easing: 'easeInOutQuad',
            translateX: [
                {value: [-25]},
                {value: [25]},
                {value: [-25]},
                {value: [25]},
            ],
            translateY: '-200px',
            opacity: [
                {value: 1},
                {value: 0}
            ],
            loop: true,
            duration: 3000
        });
        anime({
            targets: '.bubble-2',
            easing: 'easeInOutQuad',
            translateX: [
                {value: [15]},
                {value: [-15]},
                {value: [15]},
                {value: [-15]},
            ],
            translateY: '-220px',
            opacity: [
                {value: 1},
                {value: 0}
            ],
            loop: true,
            duration: 3200,
            delay: 1000
        });
        anime({
            targets: '.bubble-3',
            easing: 'easeInOutQuad',
            translateX: [
                {value: [-5]},
                {value: [25]},
                {value: [-5]},
                {value: [25]},
            ],
            translateY: '-240px',
            opacity: [
                {value: 1},
                {value: 0}
            ],
            loop: true,
            duration: 3350,
            delay: 2000
        });
    }, []);

    const bergInfo = [
        'Direkte Verbindung zum Lohnprogramm, kein Medienbruch',
        'Lückenlose, rechtskonforme Dokumentation in der Cloud',
        'Ihre Dokumente jederzeit und überall verfügbar',
        'Geführte Personalakte durch die Lohnmühle',
        '360° papierloses Verfahren',
        'Persönlicher Support',
        'DSGVO konform',
        '100% digital',
        'KUG'
    ]

    return <section id="slogan">
        <h3 className="caption">Das bieten wir</h3>
        <h2 className="title">LOHNABRECHNUNG ZUM FESTPREIS</h2>
        <button className='btn-tertiary' type='button' data-target="price">
          Zu den Preisen <KeyboardArrowRight/>
        </button>
        <div className="container">
            <div id="ice">
                <img src={logo} className="logo" alt="Lohnmühle logo" title="Lohnmühle logo"/>
                <img src={ice} className="mountain" alt="Lohnmühle iceberg"/>
                <h3>Lohnabrechnung mit lohnmühle</h3>
                <ul>
                    {_.map(bergInfo, (info, key) => {
                        return <li key={key}>{info}</li>
                    })}
                </ul>
            </div>
            <img id="boat" src={boat} alt="Lohnmühle schiff"/>
            <img className="cloud" src={w2} alt="Wolke" style={{
                top: '20%',
                left: '-70%'
            }}/>
            <img className="cloud cloud-2" src={w1} alt="Wolke" style={{
                top: '10%',
                left: '-55%',
                width: '15%'
            }}/>
            <img className="cloud" src={w2} alt="Wolke" style={{
                top: '5%',
                left: '-35%'
            }}/>
            <img className="cloud" src={w1} alt="Wolke" style={{
                top: '20%',
                left: '-25%',
                width: '50%',
            }}/>
            <img className="cloud cloud-2" src={w2} alt="Wolke" style={{
                top: '15%',
                left: '10%',
                width: '15%'
            }}/>
            <img className="cloud" src={w1} alt="Wolke" style={{
                top: '5%',
                left: '20%',
                width: '20%'
            }}/>
            <img className="cloud" src={w2} alt="Wolke" style={{
                top: '20%',
                left: '30%'
            }}/>
            <img className="cloud cloud-2" src={w1} alt="Wolke" style={{
                top: '10%',
                left: '45%',
                width: '15%'
            }}/>
            <img className="cloud" src={w2} alt="Wolke" style={{
                top: '5%',
                left: '65%'
            }}/>
            <img className="cloud" src={w1} alt="Wolke" style={{
                top: '20%',
                left: '75%',
                width: '50%',
            }}/>
            <img className="cloud cloud-2" src={w2} alt="Wolke" style={{
                top: '15%',
                left: '110%',
                width: '15%'
            }}/>
            <img className="cloud" src={w1} alt="Wolke" style={{
                top: '5%',
                left: '120%',
                width: '20%'
            }}/>
        </div>
    </section>
}

export default Slogan;