import React, { useEffect } from 'react';
import { scrollToTop } from '../utils/handyFunctions';

const Privacy = () => {
    useEffect(() => {
        document.addEventListener('scroll', scrollToTop);
        window.scrollTo(0, 0);
    }, []);
    return <section id="privacy">
        <h1>Information über die Verarbeitung personenbezogener Daten nach Art. 13 DS-GVO für die Webseite der Lohnmühle GmbH</h1>
        <p>
            Wir, die Lohnmühle GmbH, legen höchsten Wert auf den Schutz Ihrer Daten. Wir verarbeiten Ihre personenbezogenen Daten daher nur in Übereinstimmung mit dem Inhalt dieser Datenschutzerklärung sowie den Bestimmungen der EU-Datenschutzgrundverordnung (DS-GVO), des Bundesdatenschutzgesetzes (BDSG) und - soweit einschlägig - den Bestimmungen des Telemediengesetzes (TMG). Die nachstehenden Datenschutzhinweise gelten für die Datenverarbeitung auf unserer Webseite <a href="https://www.lohnmühle.de">www.lohnmühle.de</a>.
        </p>
        <h3>Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</h3>
        <p>
            Lohnmühle GmbH<br/>
            Kesselstraße 19<br/>
            D-70327 Stuttgart<br/>
            Telefon: 0711/65 66 90 83<br/>
            E-Mail: info@lohnmuehle.de
        </p>
        <h3>Verarbeitung personenbezogener Daten bei Kontaktaufnahme</h3>
        <p>
            Wenn Sie uns personenbezogene Daten durch Kontaktaufnahme zur Verfügung stellen, z.B. per E-Mail oder durch Eingabe Ihrer Daten in unser Kontaktformular, verarbeiten wir Ihre Daten gemäß Art. 6 Abs. 1 S. 1 b) DS-GVO zum Zwecke der Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage erfolgen oder gemäß Art. 6 Abs. 1 S. 1 f) DS-GVO aufgrund unseres berechtigten Interesses an der Beantwortung Ihrer Anfrage. 
            Unser Kontaktformular ist ein Tool des Anbieters Calendly LLC mit Sitz in den USA („Calendly“). Ihre Daten werden über Server von Calendly übertragen und auf Servern von Calendly bzw. von deren Unterauftragnehmern gespeichert. Calendly verarbeitet regelmäßig Daten in den USA. Mit Calendly besteht eine Vereinbarung nach den EU-Standardklauseln, welche die Angemessenheit des Datenschutzniveaus auch bei einer Verarbeitung von Daten in den USA sicherstellt (Art. 46 DSGVO). Die Rechtsgrundlage für unsere Datenverarbeitung mittels Calendly ist Art. 6 Abs. 1 S. 1 f) DS-GVO, weil wir ein berechtigtes Interesse daran haben, einen spezialisierten Dienstleister für unsere Kontaktverwaltung einzusetzen.
            Wenn ein Vertrag zwischen Ihnen und uns zustande gekommen ist und Sie dem Erhalt von weiteren Informationen und Werbung nicht widersprochen haben, dürfen wir Sie über unsere Angebote und Dienstleistungen per E-Mail auf dem Laufenden halten. Die Rechtsgrundlage für die Verarbeitung Ihrer Daten hierfür ist Art. 6 Abs. 1 S. 1 f) DS-GVO und § 7 Abs. 3 UWG. Wir haben ein berechtigtes Interesse an Direktwerbung. Sie können der Verwendung Ihrer Daten für die Zusendung solcher E-Mails jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen.
        </p>
        <h3>Datenverarbeitung bei der Anmelden-Funktion</h3>
        <p>
            Die Anmelden-Funktion auf unserer Webseite steht nur unseren Kunden zur Verfügung, die die Leistungen der Lohnmühle GmbH gebucht haben. Hierfür gelten unsere Datenschutzinformationen für Kunden und Vertragspartner, die Sie bei Vertragsschluss erhalten habe bzw. – sofern Sie als Mitarbeiter unseres Kunden handeln – die Datenschutzinformationen Ihres Arbeitgebers, dessen Auftragsverarbeiter wir sind.
        </p>
        <h3>Datenverarbeitung mittels Logfiles</h3>
        <div>
            <p>
                Beim Aufrufen unserer Webseite werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Webseite gesendet. Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst:
            </p>
            <ul>
                <li>IP-Adresse des anfragenden Rechners</li>
                <li>Datum und Uhrzeit des Zugriffs</li>
                <li>Name und URL der abgerufenen Datei</li>
                <li>Webseite, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Provider</li>
            </ul>
            <p>
                Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
            </p>
            <ul>
                <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Webseite</li>
                <li>Gewährleistung einer komfortablen Nutzung unserer Webseite</li>
                <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                <li>Aufklärung etwaiger missbräuchlicher Seitenzugriffe (DoS/DDoS-Attacken o.ä.).</li>
            </ul>
            <p>
                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 f) DS-GVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. Die Speicherung der IP-Adresse erfolgt allerdings anonymisiert, so dass in den Logfiles keine Rückschlüsse auf Ihre Person oder Ihren Internetanschluss möglich sind. In Ausnahmefällen speichern wir die vollständige IP-Adresse auf der vorgenannten Rechtsgrundlage, wenn wir ein berechtigtes Interesse hieran haben, um Angriffe auf unsere Webseiten aufklären und abwehren und unsere Rechte gegen Angreifer geltend machen zu können. Die Daten werden gelöscht, sobald der Speicherzweck erreicht ist.
            </p>
        </div>
        <h3>Sicherheit bei der Datenübertragung</h3>
        <p>
            Sofern Sie auf unserer Webseite personenbezogenen Daten eingeben können, werden diese mittels SSL-Verschlüsselung über das Internet übertragen. Wir weisen jedoch darauf hin, dass die Nutzung elektronischer oder sonstiger Kommunikationswege mit Risiken für die Vertraulichkeit der Kommunikation zwischen Ihnen und uns verbunden ist; dies gilt insbesondere für die Nutzung von E-Mails. Wenn Sie uns per E-Mail oder auf sonstige Weise elektronisch kontaktieren, dürfen wir von Ihrem Einverständnis mit der weiteren Nutzung dieser Kommunikationswege durch uns ausgehen.
            Wir sichern unsere Webseite und sonstigen Systeme durch technische und organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen.
        </p>
        <h3>Kategorien von Empfängern von Daten; Datenübermittlungen in ein Drittland</h3>
        <p>
            Von uns im Zusammenhang mit der Webseite eingesetzte Dienstleister und Erfüllungsgehilfen, z.B. Host Provider, Agenturen oder IT-Dienstleister können Zugriff auf Ihre personenbezogenen Daten haben. Sofern diese Dienstleister und Erfüllungsgehilfen für uns Daten im Auftrag verarbeiten, handeln sie weisungsgebunden und werden von uns entsprechend vertraglich verpflichtet. Eine Datenübermittlung in ein Drittland findet in den in Ziffer 2 genannten Fällen statt.
        </p>
        <h3>Ihre Rechte als Betroffener</h3>
        <div>
            <p>Sie haben das Recht:</p>
            <ul>
                <li>
                    sofern wir personenbezogene Daten auf Grundlage einer Einwilligung von Ihnen verarbeiten, gemäß Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung jederzeit uns gegenüber zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt
                </li>
                <li>
                    gemäß Art. 15 DS-GVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen
                </li>
                <li>
                    gemäß Art. 16 DS-GVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen
                </li>
                <li>
                    gemäß Art. 17 DS-GVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist
                </li>
                <li>
                    gemäß Art. 18 DS-GVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben
                </li>
                <li>
                    gemäß Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und
                </li>
                <li>
                    sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 f) DS-GVO verarbeitet werden, gemäß Art. 21 DS-GVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
                </li>
                <li>
                    sofern Ihre personenbezogenen Daten für Zwecke der Direktwerbung verarbeitet werden, gemäß Art. 21 Abs. 2 DS-GVO jederzeit der Verarbeitung Ihrer Daten für derartige Werbung, einschließlich Profiling, soweit es mit solcher Direktwerbung in Verbindung steht, zu widersprechen
                </li>
                <li>
                    sofern Ihre personenbezogenen Daten aufgrund einer Einwilligung verarbeitet werden, diese jederzeit zu widerrufen, wobei durch den Widerruf Ihrer Einwilligung die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt wird
                </li>
                <li>
                    gemäß Art. 77 DS-GVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden
                </li>
            </ul>
            <p>
                Möchten Sie von Ihren vorgenannten Rechten Gebrauch machen, wenden Sie sich unter den oben genannten Kontaktdaten an uns.
            </p>
        </div>
        <h3>Dauer der Speicherung und routinemäßige Löschung</h3>
        <p>
            Sofern in dieser Datenschutzerklärung bei der konkreten Datenverarbeitung nicht anders angegeben, verarbeiten und speichern wir personenbezogene Daten nur für den Zeitraum, der zur Erreichung des Verarbeitungszwecks erforderlich ist oder sofern dies in Gesetzen oder Vorschriften, welchen wir unterliegen, vorgesehen wurde.
            Entfällt der Speicherungszweck oder läuft eine gesetzlich vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        </p>
    </section>
}

export default Privacy;