import { KeyboardArrowRight } from '@material-ui/icons';
import React from 'react';
import check from '../assets/images/LM_list-decoration.svg';
import g from '../assets/images/LM_siegel.png';

const Why = () => {
    return <section id="why">
        <h3 className="caption">WARUM LOHNMÜHLE?</h3>
        <div className="panels">
            <div className="panel">
                <div className="panel-details">
                    <h2><img src={check} className="check" alt="Windmühle"/> <span>Einfacher Wechsel</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Wir übernehmen die Übertragung der Daten und die Kommunikation mit dem bisherigen Lohndienstleister.</li>
                    </ul>
                    <h2><img src={check} className="check" alt="Windmühle"/> <span>Garantierter Festpreis</span></h2>
                    <ul>
                        <li>
                            <KeyboardArrowRight/> Wir, von der Lohnmühle, geben Ihnen mit unserem Festpreis die Transparenz und Klarheit,
                            die Sie sich als Unternehmerin und Unternehmer wünschen. Keine versteckten Kosten.
                            Keine Vertragsbindung.
                        </li>
                    </ul>
                    <h2><img src={check} className="check" alt="Windmühle"/><span>Volle Flexibilität und Kontrolle</span></h2>
                    <ul>
                        <li>
                            <KeyboardArrowRight/> Sie können lohnrelevante Daten jederzeit korrigieren. Es werden nur die Arbeitszeiten vergütet,
                            die nach Ihrer Prüfung und Freigabe auch zu bezahlende Zeiten sind.
                        </li>
                    </ul>
                </div>
            </div>
            <img src={g} alt="Prüfungsbegleitung und Qualitätsversprechen garantiert."/>
        </div>
    </section>
}

export default Why;