import React, { useEffect } from 'react';
import { scrollToTop } from '../utils/handyFunctions';

const AGB = () => {
    useEffect(() => {
        document.addEventListener('scroll', scrollToTop);
        window.scrollTo(0, 0);
    }, []);
    return <section id="agb">
        <h1>Allgemeine Geschäftsbedingungen (AGB) der Lohnmühle GmbH</h1>
        <h2>Präambel</h2>
        <p>
            Die Lohnmühle GmbH mit Sitz in Stuttgart bietet ihren Kunden die Erstellung von Lohnabrechnungen an, wobei die Leistungen
            ausschließlich unter Verwendung des Programms edlohn der eurodata AG mit Sitz in Saarbrücken erbracht werden. Voraussetzung
            für die Inanspruchnahme der Dienstleistungen der Lohnmühle GmbH ist daher, dass der Kunde über die gesamte Laufzeit des
            Vertrags mit der Lohnmühle GmbH Lizenzen für die Programme edtime oder edpep der eurodata AG gebucht hat. Für die Erbringung der Leistungen der Lohnmühle GmbH gelten die nachstehenden AGB.
        </p>
        <h3>§ 1 Vertragsgegenstand; Beauftragung Dritter</h3>
        <p>
            <span className="bold">(1)</span>
            Gegenstand dieses Vertrags ist die Erbringung von Lohnabrechnungsleistungen durch die Lohnmühle GmbH mittels der Drittanbieter-Software edlohn der eurodata AG. Die Lohnmühle GmbH leistet keine Steuer- und Rechtsberatung. Die genauen von der
            Lohnmühle GmbH gegenüber dem jeweiligen Kunden zu erbringenden Leistungen der Lohnmühle GmbH ergeben sich aus der
            Vereinbarung mit dem Kunden sowie aus dem Leistungsverzeichnis der Lohnmühle GmbH.
        </p>
        <p>
            <span className="bold">(2)</span>
            Die Lohnmühle GmbH übernimmt weder ganz noch teilweise Aufgaben der Personalverwaltung für den Kunden. Die
            Personalverwaltung verbleibt in der alleinigen Verantwortung des Kunden. Die Lohnmühle GmbH erfasst, speichert, verarbeitet und
            nutzt die zur Lohnabrechnung übermittelten Daten im Auftrag des Kunden. Soweit es sich um personenbezogene Daten handelt,
            erfolgt die Datenverarbeitung im Rahmen einer Auftragsverarbeitung (siehe auch § 6 dieser AGB).
        </p>
        <p>
            <span className="bold">(3)</span>
            Die Lohnmühle GmbH wird – vorbehaltlich einer jederzeit abweichenden Einzelweisung des Kunden – die für den Kunden im
            Rahmen der vertraglichen Leistungserbringung bearbeiteten Unterlagen für die Dauer des Vertrags sowie ein Jahr darüber hinaus
            revisionssicher speichern. Die Aufbewahrungs- und Speicherverpflichtung endet ungeachtet gesetzlicher Pflichten des Kunden
            zur Aufbewahrung ein Jahr nach Beendigung des mit dem Kunden geschlossenen Vertrags – gleich aus welchem Rechtsgrund.
            Die Speicherverpflichtung der Lohnmühle GmbH für ein weiteres Jahr nach Vertragsbeendigung endet mit dem Ablauf desjenigen
            Tages des letzten Monats dieses Jahres, welcher dem Tage vorhergeht, der durch seine Benennung oder seine Zahl dem Tag der
            Vertragsbeendigung entspricht (Bsp.: Vertrag endet am 6.2.2020, Speicherpflicht endet am 5.2.2021, 24.00 Uhr). Während dieses
            Jahres kann der Kunde weiter auf die edcloud zugreifen, um seine Daten herunterzuladen. Weitere Funktionen stehen ihm jedoch
            nach Vertragsbeendigung nicht mehr zur Verfügung. Der Kunde ist während der Vertragslaufzeit sowie im Jahreszeitraum nach
            den vorstehenden Sätzen jederzeit berechtigt, gegen gesondertes Entgelt nach Maßgabe der jeweils gültigen Preisliste sowie des
            gültigen Leistungsverzeichnisses einen von der Lohnmühle GmbH gewählten Datenträger mit allen von ihm im Rahmen der
            Leistungserbringung gespeicherten Daten bei der Lohnmühle GmbH zu bestellen.
        </p>
        <p>
            <span className="bold">(4)</span>
            Die Lohnmühle GmbH ist berechtigt, sich – vorbehaltlich getroffener abweichender Vereinbarungen zur Auftragsverarbeitung
            – zur Erbringung der vereinbarten Leistungen Dritter als Unterauftragnehmer zu bedienen, bleibt jedoch für die vertragsgemäße
            Leistungserbringung gegenüber dem Kunden allein verantwortlich.
        </p>
        <h3 id="system">§ 2 Mitwirkungspflichten des Kunden; Übermittlungswege; Befreiung des Steuerberaters von der Verschwiegenheitspflicht</h3>
        <p>
            <span className="bold">(1)</span>
            Der Kunde bucht selbst und auf eigene Rechnung bei der eurodata AG die für die Erbringung der mit der Lohnmühle GmbH
            vereinbarten Lohnabrechnungsleistungen notwendigen Lizenzen für die Software edtime oder edpep. Diese sind während der
            gesamten Vertragslaufzeit (§ 7 dieser AGB) mit der Lohnmühle GmbH aufrecht zu erhalten.
        </p>
        <p>
            <span className="bold">(2)</span>
            Der Kunde unterstützt die Lohnmühle GmbH bei der Erbringung der geschuldeten Leistungen im erforderlichen Umfang und wird
            insbesondere der Lohnmühle GmbH auf eigene Kosten und rechtzeitig zur Einhaltung vertraglicher und/oder gesetzlicher Fristen alle
            für die Durchführung des Vertrags notwendigen Informationen und Unterlagen über die Software edtime oder edpep und die
            edcloud zur Verfügung stellen. Informationen und Unterlagen, die auf anderem Wege übermittelt werden, werden für die Erbringung
            der Leistungen nur dann beachtet, wenn dies mit der Lohnmühle GmbH zuvor mindestens in Textform vereinbart wurde. Die
            Lohnmühle GmbH kann hierfür eine Vergütung gemäß ihrer aktuell gültigen Preisliste verlangen. Für die Zurverfügungstellung ist
            erforderlich, dass der Kunde innerhalb von edtime oder edpep einen Abschluss macht und so die Daten an die Lohnmühle GmbH über
            die edcloud übermittelt.
        </p>
        <p>
            <span className="bold">(3)</span>
            Der Kunde wird die Lohnabrechnungsdaten in der Software edtime bzw. edpep regelmäßig und für die Einhaltung von Fristen zur
            Lohnabrechnung rechtzeitig pflegen.
        </p>
        <p>
            <span className="bold">(4)</span>
            Der Kunde versichert, dass die von ihm mitgeteilten Werte, auf denen die weitere Lohnabrechnung der Lohnmühle GmbH aufbaut,
            richtig sind.
        </p>
        <p>
            <span className="bold">(5)</span>
            Zur Vorbereitung der Lohnabrechnungs-Leistungen der Lohnmühle GmbH braucht diese die Vortragswerte aus der
            Lohnbuchhaltung des Kunden. Soweit erforderlich, wird der Kunde daher seinen Steuerberater oder sonstigen vorherigen
            Dienstleister von etwaigen gesetzlichen oder vertraglichen Verschwiegenheitsverpflichtungen befreien und der Lohnmühle GmbH
            bzw. deren hierfür beauftragtem Unterauftragnehmer eine Berechtigung erteilen, mit der die Vortragswerte eigenständig eingeholt
            werden können.
        </p>
        <p>
            <span className="bold">(6)</span>
            Für das Übersenden der Daten an die Lohnmühle GmbH ist eine Internetverbindung nötig, für die der Kunde selbst sorgen muss.
        </p>
        <p>
            <span className="bold">(7)</span>
            Unterlässt der Kunde eine ihm nach den vorstehenden Absätzen oder sonst obliegende Mitwirkung oder kommt er mit der
            Annahme der von der Lohnmühle GmbH angebotenen Leistungen in Verzug, ist die Lohnmühle GmbH nach schriftlicher Setzung einer
            angemessenen Frist für die Mitwirkung oder Leistung berechtigt, den Vertrag fristlos zu kündigen. Unberührt bleibt der Anspruch der
            Lohnmühle GmbH auf Ersatz der ihr durch den Verzug oder die unterlassene Mitwirkung des Kunden entstandenen
            Mehraufwendungen sowie des verursachten Schadens, und zwar auch dann, wenn die Lohnmühle GmbH von dem Kündigungsrecht
            keinen Gebrauch macht. Kann die Lohnmühle GmbH ihre Leistungen nicht, nicht vollständig oder nicht rechtzeitig erbringen, weil
            der Kunde seine Mitwirkungspflichten nicht erfüllt, so kann der Kunde hieraus keine Ansprüche gegen die Lohnmühle GmbH geltend
            machen.
        </p>
        <h3>§ 3 Ausführung der Leistungen; Umzug der Daten</h3>
        <p>
            <span className="bold">(1)</span>
            Nach Abschluss des Vertrags wird die Lohnmühle GmbH bzw. ein von ihr beauftragten Unterauftragnehmer die Daten vom
            bisherigen System des Kunden oder seiner Dienstleister eigenständig herunterladen und in edlohn einpflegen. Sodann werden für
            den Kunden und in dessen Auftrag eine Firmen- und Mitarbeiter-Stammdateien sowie weitere Dateien eingerichtet, soweit diese für
            die Erfüllung der vereinbarten Abrechnungsleistungen erforderlich sind.
        </p>
        <p>
            <span className="bold">(2)</span>
            Die Lohnmühle GmbH erbringt die vereinbarten monatlichen Abrechnungsleistungen nach der Zurverfügungstellung durch den
            Kunden (§ 2 Abs. 2 S. 4 dieser AGB) bis zum Abrechnungstermin und sendet die Abrechnung direkt an die Behörden und an den
            Kunden.
        </p>
        <p>
            <span className="bold">(3)</span>
            Zur Gewährleistung der Hochrechnung der monatlichen Beitragsschuld-Schätzung durch die Lohnmühle GmbH (gesetzliche
            Verpflichtung zur vorgezogenen Beitragsschuldermittlung sowie Vorauszahlung der SV-Beiträge), ist der Kunde verpflichtet die
            vereinbarten Termine zur Übergabe der monatlichen Änderungsdaten für die vorläufige und endgültige Berechnung der
            Beitragsschuld zwingend einzuhalten.
        </p>
        <p>
            <span className="bold">(4)</span>
            Die Lohnmühle GmbH ist berechtigt, bei Vorliegen eines wichtigen Grundes anstelle der geschuldeten Lohnabrechnungen
            zunächst Abschlagsabrechnungen zu erteilen. Ein wichtiger Grund liegt z.B. vor
        </p>
        <ul>
            <li>
                bei erforderlicher Einarbeitung kurzfristiger Gesetzesänderungen,
            </li>
            <li>
                unvorhergesehenen und von der Lohnmühle GmbH nicht zu vertretenden Personalengpässen auf
                Grund von Krankheit (z. B. im Fall von Epidemien/Pandemien).
            </li>
        </ul>
        <p>
            <span className="bold">(5)</span>
            Die Durchführung von Abschlagsabrechnungen ist dem Kunden unverzüglich mit Bekanntwerden des wichtigen Grundes in
            schriftlicher oder elektronischer Form mitzuteilen. Die Lohnmühle GmbH ist verpflichtet, die vollständige Abrechnung innerhalb
            einer Frist von 10 Tagen ab Versendung der Abschlagsabrechnungen zu erstellen und an den Kunden abzusenden.
        </p>
        <p>
            <span className="bold">(6)</span>
            Die Erbringung der vertragsgegenständlichen Leistungen durch die Lohnmühle GmbH erfolgt ausschließlich während der
            Servicezeiten der Lohnmühle GmbH.
        </p>
        <h3>§ 4 Vergütung und Zahlungsbedingungen; Anpassung der Vergütung bei Veränderung im Mitarbeiterbestand</h3>
        <p>
            <span className="bold">(1)</span>
            Die monatliche Vergütung der Lohnmühle GmbH setzt sich aus den in der mit dem Kunden geschlossenen Vereinbarung
            dargestellten Kosten zusammen.
        </p>
        <p>
            <span className="bold">(2)</span>
            Der Kunde kann die Zahl der Mitarbeiter während der Vertragslaufzeit flexibel anpassen. Die monatlichen Kosten werden dann
            entsprechend angepasst.
        </p>
        <p>
            <span className="bold">(3)</span>
            Nach Zeitaufwand zu ihrem Stundensatz rechnet die Lohnmühle GmbH nur ab, wenn der Kunde Leistungen abfragt, die nicht
            Gegenstand der mit der Lohnmühle GmbH vereinbarten Leistungen sind.
        </p>
        <p>
            <span className="bold">(4)</span>
            Die Lohnmühle GmbH stellt ihre Leistungen monatlich in Rechnung. Der Rechnungsversand für Kunden erfolgt ausschließlich
            elektronisch per E-Mail. Die Rechnung ist spätestens eine Woche nach Erhalt ohne Abzug zu bezahlen. Der Kunde kann der
            Lohnmühle GmbH ein SEPA-Lastschriftmandat erteilen.
        </p>
        <p>
            <span className="bold">(5)</span>
            Zahlt der Kunde trotz Fälligkeit eines Rechnungsbetrags nicht bzw. scheitert eine Abbuchung vom Konto des Kunden, ist die
            Lohnmühle GmbH berechtigt, die weitere Leistungserbringung für den Kunden bis zum Ausgleich aller offenen Beträge einzustellen.
            Die Lohnmühle GmbH wird den Kunden hierauf vor Einstellung der Leistungen gesondert per E-Mail an die vom Kunden hinterlegte
            E-Mail-Adresse unter einer Nachfristsetzung von 5 Werktagen hinweisen.
        </p>
        <h3>§ 5 Gewährleistung</h3>
        <p>
            <span className="bold">(1)</span>
            Aufgetretene Mängel und Fehler sind der Lohnmühle GmbH unmittelbar nach ihrer Feststellung in schriftlicher oder
            elektronischer Form nachvollziehbar mitzuteilen. Erkennbare Mängel sind spätestens innerhalb von fünf Werktagen
            mitzuteilen. Versteckte Mängel sind unverzüglich nach Kenntniserlangung mitzuteilen. Im Übrigen gilt die handelsrechtliche
            Rügeobliegenheit uneingeschränkt.
        </p>
        <p>
            <span className="bold">(2)</span>
            Gelingt der Lohnmühle GmbH die Beseitigung der angezeigten Mängel oder Fehler bei der nächsten Lohnabrechnung nach
            Eingang der Mängelanzeige nicht, so stehen dem Kunden die jeweiligen weitergehenden gesetzlichen Gewährleistungsrechte zu.
            Ergibt eine Überprüfung, dass kein von der Lohnmühle GmbH zu vertretender Mangel oder Fehler vorgelegen hat, so kann die
            Lohnmühle GmbH eine Aufwandserstattung nach ihrem Stundensatz verlangen.
        </p>
        <p>
            <span className="bold">(3)</span>
            Die Gewährleistungsfrist beträgt ein Jahr.
        </p>
        <p>
            <span className="bold">(4)</span>
            Die Lohnmühle GmbH haftet nicht für Fehler und Ausfälle bei der Übertragung der Daten von edtime oder edpep in das System
            der Lohnmühle GmbH. Etwas anderes gilt nur, wenn der Fehler im Verantwortungsbereich der Lohnmühle GmbH liegt.
        </p>
        <h3>§ 6 Geheimhaltung; Datenschutz</h3>
        <p>
            Die Lohnmühle GmbH wird die im Rahmen der Geschäftsbeziehung mit dem Kunden zur Auftragsbearbeitung und -abwicklung
            erhaltenden Informationen und Daten geheim halten. Personenbezogene Daten des Kunden bzw. seiner Mitarbeiter werden unter
            Beachtung aller datenschutzrechtlichen Vorschriften verarbeitet. Der Kunde ist verpflichtet, mit der Lohnmühle GmbH einen
            Auftragsverarbeitungsvertrag nach Art. 28 DS-GVO abzuschließen.
        </p>
        <h3>§ 7 Vertragslaufzeit; Folgen der Vertragsbeendigung; Fortgeltung von Regelungen</h3>
        <p>
            <span className="bold">(1)</span>
            Der Vertrag kommt mit Unterzeichnung der Vereinbarung durch beide Parteien bzw. durch deren Vertreter zustande. Die
            vereinbarte Vertragslaufzeit beginnt ab dem in der Vereinbarung aufgeführten Vertragsstart. Vertragslaufzeit, automatische
            Verlängerung und Kündigungsfrist richten sich nach der Vereinbarung zwischen den Parteien. Haben die Parteien nichts anderes
            vereinbart, gilt eine Vertragslaufzeit von 2 Monaten ab Vertragsstart mit einer automatischen Verlängerung um jeweils 1 Monat,
            wenn nicht eine der Parteien bis zum letzten Tag des Monats kündigt, der dem letzten Monat der Vertragslaufzeit vorangeht (Bsp.:
            Vertrag läuft bis zum 31.7.2020, letzter möglicher Tag für eine Kündigung ist der 30.6.2020, ansonsten verlängert sich der Vertrag
            bis zum 31.8.2020).
        </p>
        <p>
            <span className="bold">(2)</span>
            Das Recht zur außerordentlichen fristlosen Kündigung aus wichtigem Grunde bleibt unberührt.
        </p>
        <p>
            <span className="bold">(3)</span>
            Die Kündigung bedarf der Schriftform, wobei für den Zugang die Übersendung per E-Mail als eingescanntes Dokument
            ausreichend ist. Für die Wirksamkeit der Kündigung ist ihr Zugang beim Empfänger maßgeblich.
        </p>
        <p>
            <span className="bold">(4)</span>
            Endet der Vertrag mit der Lohnmühle GmbH, gilt für die Speicherung der Daten § 1 (3) dieser AGB. Nach Ablauf der darin
            genannten Zeiträume wird die Lohnmühle GmbH die gespeicherten Daten unwiederbringlich löschen. Die Lohnmühle GmbH wird
            den Kunden 4 Wochen vor Ende des in § 1 (3) dieser AGB genannten Jahreszeitraums auf die bevorstehende Löschung in Textform
            an die bei ihr hinterlegte E-Mail-Adresse des Kunden hinweisen.
        </p>
        <p>
            <span className="bold">(5)</span>
            Die Löschpflicht nach vorstehendem Absatz gilt nicht für Datensätze bzw. Teile von Datensätzen, die die Lohnmühle GmbH
            aufgrund rechtlicher Pflichten (z.B. handels- und steuerrechtlicher Regeln) aufbewahren muss oder die sie zur Geltendmachung
            oder Abwehr von Ansprüchen gegenüber den Betroffenen weiterhin benötigt. Die Lohnmühle GmbH wird dem Kunden mitteilen,
            aufgrund welcher Rechtsgrundlagen und für welchen Zeitraum sie vertragsgegenständliche Daten weiterverarbeiten muss. Ist
            die Rechtsgrundlage nicht mehr gegeben, wird sie auch diese Datensätze ohne Aufforderung unverzüglich und unwiederbringlich
            löschen.
        </p>
        <p>
            <span className="bold">(6)</span>
            Solange die Lohnmühle GmbH Daten des Kunden für diesen sichert (siehe auch § 1 (3) dieser AGB) gelten die § 1 (3), (4), § 5, §
            6, § 7 (4) (5) und (6), § 8, § 9, § 10 sowie § 11 dieser AGB für die Datensicherung fort.
        </p>
        <h3>§ 8 Aufrechnung; Zurückbehaltung</h3>
        <p>
            <span className="bold">(1)</span>
            Ein Recht zur Aufrechnung steht dem Kunden nur dann zu, wenn seine Gegenansprüche rechtskräftig gerichtlich festgestellt
            oder unbestritten sind oder schriftlich durch die Lohnmühle GmbH anerkannt wurden. Zur Aufrechnung gegen Ansprüche der
            Lohnmühle GmbH bleibt der Kunde jedoch auch ohne vorstehende Einschränkung berechtigt, wenn er Mängelrügen oder
            Gegenansprüche aus demselben Vertrag geltend macht.
        </p>
        <p>
            <span className="bold">(2)</span>
            Ein Zurückbehaltungsrecht kann der Kunde nur ausüben, soweit seine Ansprüche auf demselben Vertragsverhältnis beruhen.
        </p>
        <h3>§ 9 Beschränkung der Haftung</h3>
        <p>Die Lohnmühle GmbH haftet, gleich aus welchem Rechtsgrund, ausschließlich nach Maßgabe der nachstehenden Regelungen.</p>
        <p>
            <span className="bold">(1)</span>
            Eine Haftung der Lohnmühle GmbH besteht nur für Vorsatz und grobe Fahrlässigkeit. Für leichte Fahrlässigkeit haftet die
            Lohnmühle GmbH nur bei Verletzung einer wesentlichen Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des
            Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf (Kardinalpflicht). Die Lohnmühle
            GmbH haftet dabei nur für vorhersehbare Schäden, mit deren Entstehung typischerweise gerechnet werden muss. Dies gilt auch für
            entgangenen Gewinn und ausgebliebene Einsparungen. Die Haftung für sonstige entfernte Mangelfolgeschäden ist
            ausgeschlossen.
        </p>
        <p>
            <span className="bold">(2)</span>
            Die Beschränkung der Haftung der Lohnmühle GmbH gilt nicht bei einer Verletzung von Leben, Körper und/oder Gesundheit und
            bei einer Haftung nach dem Produkthaftungsgesetz.
        </p>
        <p>
            <span className="bold">(3)</span>
            Die Lohnmühle GmbH haftet nicht für Ereignisse höherer Gewalt, welche die vertragsgegenständlichen Leistungen unmöglich
            machen oder auch nur die ordnungsgemäße Vertragsdurchführung wesentlich erschweren oder zeitweilig behindern. Als höhere
            Gewalt gelten alle Umstände, die vom Willen und Einfluss der Vertragsparteien unabhängig sind, wie Terroranschläge, Embargo,
            Beschlagnahme, Naturkatastrophen, Streiks, Behördenentscheidungen oder sonstige, von den Vertragsparteien unverschuldete,
            schwerwiegende und unvorhersehbare Umstände. Ein Umstand gilt dabei nur dann als höhere Gewalt, wenn er nach Abschluss des
            Vertrags eingetreten ist.
        </p>
        <p>
            <span className="bold">(4)</span>
            Soweit die Haftung der Lohnmühle GmbH ausgeschlossen oder beschränkt ist, gilt dies auch für die Haftung der Arbeitnehmer,
            sonstigen Mitarbeiter, Vertreter und Erfüllungsgehilfen der Lohnmühle GmbH.
        </p>
        <h3>§ 10 Änderung dieser AGB</h3>
        <p>
            <span className="bold">(1)</span>
            Die Lohnmühle GmbH ist berechtigt, diese AGB zu ändern, soweit dies zur Anpassung an rechtliche oder tatsächliche Entwicklungen erforderlich ist, welche bei Vertragsschluss nicht vorhersehbar waren und welche die Lohnmühle GmbH nicht beeinflussen
            kann und deren Nichtberücksichtigung die Ausgewogenheit des Vertragsverhältnisses in nicht unbedeutendem Maße stören würde.
            Ferner können Anpassungen erfolgen, soweit dies zur Beseitigung von nicht unerheblichen Schwierigkeiten bei der Durchführung
            des Vertrags aufgrund von nach Vertragsschluss entstandenen Regelungslücken erforderlich ist; insbesondere, wenn eine oder
            mehrere Bestimmungen dieser Vertragsbedingungen aufgrund von rechtskräftigen gerichtlichen Entscheidungen oder
            Gesetzesänderungen unwirksam werden oder unwirksam zu werden drohen.
        </p>
        <p>
            <span className="bold">(2)</span>
            Änderungen werden jeweils zum Monatsbeginn und erst nach Bekanntgabe an den Kunden wirksam. Sie werden dem Kunden
            in Textform mindestens sechs Wochen vor ihrem Wirksamwerden angekündigt. Im Falle der Änderung ist der Kunde berechtigt, den
            Vertrag ohne Einhaltung einer Frist in Textform zu kündigen oder der Änderung zu widersprechen. Kündigt der Kunde nicht bzw.
            widerspricht der Kunde nicht, gelten die geänderten Bedingungen ab dem angekündigten Zeitpunkt. Widerspricht der Kunde den
            Änderungen, gelten die bisherigen Vertragsbedingungen unverändert fort. In diesem Fall ist die Lohnmühle GmbH berechtigt, den
            Vertrag mit einer Frist von einem Monat zum Ende des Kalendermonats in Textform zu kündigen. Die Lohnmühle GmbH wird den
            Kunden auf diese Folgen in der Ankündigung der Vertragsanpassung hinweisen.
        </p>
        <h3>§ 11 Entgegenstehende Bedingungen</h3>
        <p>
            Entgegenstehende oder von diesen AGB inhaltlich abweichende Geschäftsbedingungen von Seiten des Kunden werden
            ausdrücklich nicht anerkannt, es sei denn, die Lohnmühle GmbH stimmt deren Geltung ausdrücklich schriftlich zu. Dies gilt auch
            dann, wenn der Kunde die Vereinbarung unter Verweis auf eigene Vertragsbedingungen unterzeichnet und die Lohnmühle GmbH
            dem in der Vereinbarung nicht ausdrücklich widerspricht. Auch wenn die Lohnmühle GmbH auf ein Schreiben Bezug nimmt, das
            Geschäftsbedingungen des Kunden oder eines Dritten enthält oder auf solche verweist, liegt darin kein Einverständnis in die
            Geltung jener Geschäftsbedingungen. Der Einbeziehung derartiger Bedingungen des Kunden wird schon jetzt widersprochen.
        </p>
        <h3>§ 12 Salvatorische Klausel, Gerichtsstand</h3>
        <p>
            <span className="bold">(1)</span>
            Sollten einzelne Bestimmungen dieses Vertrags ganz oder teilweise unwirksam oder undurchführbar sein oder nach
            Vertragsschluss unwirksam oder undurchführbar werden, so bleibt die Gültigkeit im Übrigen unberührt. Die Parteien verpflichten
            sich für diesen Fall, die ungültige Bestimmung durch eine wirksame Bestimmung zu ersetzen, die dem wirtschaftlichen Zweck der
            ungültigen Bestimmung möglichst nahekommt. Entsprechendes gilt für etwaige Lücken der Vereinbarungen.
        </p>
        <p>
            <span className="bold">(2)</span>
            Sofern es sich bei dem Kunden um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
            Sondervermögen handelt, unterliegen Streitigkeiten aus oder im Zusammenhang mit einem Vertrag mit der Lohnmühle GmbH der
            ausschließlichen Zuständigkeit der Gerichte am Sitz der Lohnmühle GmbH (z.Zt. Stuttgart). Dies gilt auch, wenn der Kunde seinen
            Sitz außerhalb des Hoheitsgebiets der Bundesrepublik Deutschland und der Vertrag oder Ansprüche aus dem Vertrag der
            beruflichen oder gewerblichen Tätigkeit des Kunden zugerechnet werden können.
        </p>
    </section>
}

export default AGB;