import React, { useEffect, useState } from 'react';
import anime from 'animejs';
import classnames from 'classnames';
import logo from '../assets/images/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { ExitToApp, KeyboardArrowLeft } from '@material-ui/icons';

const Navigation = ({isNotHome, toSection}) => {
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        anime({
            targets: '#logo-min',
            easing: 'easeInOutQuad',
            rotate: 360,
            loop: true,
            delay: 10000,
            duration: 3000
          });
    }, []);
    return <header id="navigation" className={classnames({'has-background': isNotHome, 'mobile-open': isOpen})}>
        <Link to="/" data-target="hero" id="name">Lohnmühle <img src={logo} alt="Lohnmühle" id="logo-min"/></Link>
        {window.innerWidth < 978 && !isNotHome && !history.location.pathname.includes('leistungsverzeichnis') && <div className={classnames("menu-bar", {'open': isOpen})} onClick={() => setIsOpen(!isOpen)}>
            <span className="line-1"/>
            <span className="line-2"/>
            <span className="line-3"/>
        </div>}
        <nav className={classnames({'open': isOpen})}>
            <ul>
                {!isNotHome && <li data-target="slogan" onClick={() => setIsOpen(false)}>Das bieten wir</li>}
                {!isNotHome && <li data-target="about" onClick={() => setIsOpen(false)}>Was macht die Lohnmühle?</li>}
                {!isNotHome && <li data-target="branches" onClick={() => setIsOpen(false)}>Branchen</li>}
                {!isNotHome && <li data-target="why" onClick={() => setIsOpen(false)}>Warum Lohnmühle?</li>}
                {!isNotHome && <li data-target="price" onClick={() => setIsOpen(false)}>Preise</li>}
                {!isNotHome && <li className="login" onClick={() => setIsOpen(false)}><a href="https://apps.eurodata.de/a/edcloud/" target="blank"><ExitToApp/> Anmelden</a></li>}
                {!isNotHome && <li data-target="calendar" className="btn-primary btn" onClick={() => setIsOpen(false)}>Termin</li>}
                {isNotHome && history.location.pathname.includes('leistungsverzeichnis') && <li onClick={() => {
                    toSection('price');
                    setIsOpen(false);
                }}><Link to="/" className="back-btn"><KeyboardArrowLeft/> Preise</Link></li>}
            </ul>
        </nav>
    </header>
}

export default Navigation;