import React, { useState } from 'react';
import * as _ from 'lodash';
import classnames from 'classnames';
import {branchesArr} from '../utils/consts';

const Branches = () => {
    const [toggle, setToggle] = useState(false);
    return <section id="branches">
        <h3 className="caption">Branchen</h3>
        <div className="branches-container">
            <div className="main-q">
                <div className="ring">
                    <span className="f-1"/>
                    <span className="f-2"/>
                    <span className="f-3"/>
                    <span className="q">?</span>
                </div>
                <p>Hier könnte Ihre Branche stehen</p>
            </div>
            <div className="branches-box">
                <div className={classnames("flex-box", {'toggled': !toggle})}>
                    {_.map(branchesArr, (branch, index) => {
                        return <div className="branch" key={index}>
                            <div className="img-ic">
                                <img src={branch.icon} alt={branch.name}/>
                            </div>
                            <p>{branch.name}</p>
                        </div>
                    })}
                </div>
                <button className="btn-tertiary" onClick={() => setToggle(!toggle)}>{!toggle ? 'Mehr anzeigen' : 'Weniger anzeigen'}</button>
            </div>
        </div>
    </section>
}

export default Branches;