import React, { useEffect } from 'react';
import anime from 'animejs';
import { Image, KeyboardArrowRight } from '@material-ui/icons';
import img1 from '../assets/images/bild_1.png';
import img2 from '../assets/images/bild_2.png';
import check from '../assets/images/LM_list-decoration.svg';

const About = () => {
    useEffect(() => {
        const scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
        document.addEventListener('scroll', () => {
            document.querySelectorAll('.panel').forEach(el => {
                if (scrollElement > el.offsetTop - 100) {
                    anime({
                        targets: el,
                        translateX: 1,
                        easing: 'easeInOutQuad',
                        direction: 'alternate',
                        duration: 1000
                    });
                }
            });
        });
    }, []);
    return <section id="about">
        <div className="panels">
            <div className="panel">
                <h3 className="caption">WAS MACHT DIE LOHNMÜHLE FÜR SIE?</h3>
                <h2 className="title"><span className="border-bottom">3</span>60° PAKET FÜR DIE LOHNABRECHNUNG</h2>
                <div className="panel-details">
                    <p>
                        Die Lohnmühle bietet Ihnen ein 360° Paket für die Lohnabrechnung.
                        Verwalten Sie Ihr Personal ohne Papier und überführen Sie Ihre
                        gewohnten Prozesse in die digitale Welt. Dabei behalten Sie volle
                        Flexibilität und Kontrolle. So wird alles einfacher und sicherer.
                    </p>
                    <h2><img src={check} className="check" alt="Windmühle"/> <span>Unser 360° Paket beinhaltet:</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Lohnbuchhaltung</li>
                        <li><KeyboardArrowRight/> Zeiterfassung</li>
                        <li><KeyboardArrowRight/> Archivierung aller prüfungsrelevanten Dokumente</li>
                        <li><KeyboardArrowRight/> Personaleinsatzplanung</li>
                    </ul>
                    <h2><img src={check} className="check" alt="Windmühle"/> <span>Wir sind immer für Sie da:</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Begleitung bei der Lohnsteueraußenprüfung</li>
                        <li><KeyboardArrowRight/> Begleitung bei der Sozialversicherungsprüfung</li>
                    </ul>
                </div>
                {true ? <img src={img1} alt="WAS MACHT DIE LOHNMÜHLE?"/>: <Image className="empty"/>}
            </div>
            <div className="panel">
                <h2 className="title"><span className="border-bottom">D</span>ER WEG ZU IHRER LOHNABRECHNUNG</h2>
                <div className="panel-details">
                    <h2><img src={check} className="check" alt="Windmühle"/><span>Sie nutzen edtime</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Zeiterfassung</li>
                        <li><KeyboardArrowRight/> Personaleinsatzplanung (optional)</li>
                    </ul>
                    <h2><img src={check} className="check" alt="Windmühle"/><span>Sie senden Ihren Monatsabschluss aus edtime an die Lohnmühle</span></h2>
                    <h2><img src={check} className="check" alt="Windmühle"/><span>Die Lohnmühle erstellt Ihre Lohnabrechnung</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Alle Lohnabrechnungen sind jederzeit in Ihrem Portal online verfügbar</li>
                    </ul>
                    <h2><img src={check} className="check" alt="Windmühle"/><span>Lohnabrechnung digital für Mitarbeiter freigeben</span></h2>
                    <ul>
                        <li><KeyboardArrowRight/> Geben Sie auf Wunsch die Lohnabrechnungen für Ihre Mitarbeiter frei</li>
                        <li><KeyboardArrowRight/> Mitarbeiter können ihre eigene Lohnabrechnung jederzeit digital einsehen</li>
                    </ul>
                </div>
                {true ? <img src={img2} alt="WARUM LOHNMÜHLE?"/>: <Image className="empty"/>}
            </div>
        </div>
    </section>
}

export default About;