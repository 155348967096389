import React from 'react';
import { Link } from 'react-router-dom';
import { InlineWidget } from "react-calendly";

const Calendar = () => {

    return <section id="calendar">
        <div className="flex-box">
            <div className="calendar">
                <h3 className="caption">Termin</h3>
                <h2 className="title">Wir freuen uns, Sie kennenzulernen</h2>
                <p className="text-center">Bitte nehmen Sie unsere <Link to={'/datenschutz'}>Datenschutzinformation</Link> zur Kenntnis.</p>
                <div id="calendly">
                    <InlineWidget url="https://calendly.com/lohnmuehle/erstgespraech" style={{minWidth:320, height: 900, transform: 'scale(0.9)'}}/>
                </div>
            </div>
        </div>
    </section>
}

export default Calendar;