import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  activeOnScroll,
  scrollToTop,
  setActive
} from '../utils/handyFunctions';
import * as _ from 'lodash';
import logo from '../assets/images/LM Logo.png';
import anime from 'animejs';
import classnames from 'classnames';
import { AccessTime } from '@material-ui/icons';

const Specifications = () => {
  let leistung = [
    {
      title: 'Integrierte Lohnabrechnung (Preis pro Abrechnung)',
      description:
        'Lohnabrechung (Erläuterung: Preis pro Abrechnung = Jeder angemeldete Mitarbeiter)',
      price: '11,38€'
    },
    {
      title: 'edcloud (cloud basierte Archivierung)',
      description: 'DSGVO konform - RZ eurodata',
      price: '3,93€'
    },
    {
      title: 'EDV Kosten Rechenzentrum',
      description:
        'pro Abrechnung (Erläuterung: Preis pro Abrechnung = Jeder angemeldete Mitarbeiter)',
      price: '1,32€'
    },
    {
      title: 'ETL Beratungspauschale',
      description:
        'Die ETL Rechtsanwälte stehen Ihnen beratend zu allen arbeitsrechtlichen Themen garantiert eine Stunde pro Monat telefonisch zur Verfügung.',
      price: '19,00€'
    },
    {
      title:
        'Lohnabrechnung als Volldienstleistung (Entgeltabrechnung als Volldienstleistung)',
      description: [
        'Digitale Datenannahme über edcloud oder über edtime',
        'Plausibilitätsprüfung',
        'Erfassung von allen über edtime oder der edcloud übermittelten Bewegungsdaten in der Schnellerfassung',
        'Berechnung von Entgeltfortzahlung',
        'Erstellung der Beitragsschätzung und Beitragsabrechnung',
        'Erstellung von Lohnsteueranmeldung und Lohnsteuerbescheinigung'
      ],
      price: 'inklusive'
    },
    {
      title: 'Persönlicher Ansprechpartner (plus Stellvertreter)',
      description: '',
      price: 'inklusive'
    },
    {
      title: 'Erstellung sämtlicher Meldungen',
      description: '',
      price: 'inklusive'
    },
    {
      title: 'Korrespondenz mit Sozialversicherungsträgern',
      description: '',
      price: 'inklusive'
    },
    {
      title: 'Prüfungsbegleitung',
      description: '',
      price: 'inklusive'
    }
  ];
  let leistungumfang = [
    {
      title: 'eLohnakte (Mitarbeiterakte)',
      description: [
        'Alle Dokumente für Ihre Mitarbeiter jederzeit online verfügbar.',
        'Entgeltabrechnung',
        'Zeitdokument',
        'Arbeitsnachweise',
        'Kurzarbeitnachweise',
        'Phantomlohndokument usw.'
      ],
      price: 'inklusive',
      nr: 1
    },
    {
      title: 'Firmenakte',
      description: [
        'Entgeltabrechungen aller Mitarbeiter',
        'Auszahlungslisten usw.'
      ],
      price: 'inklusive',
      nr: 2
    },
    {
      title: 'Pendelordner',
      description: [
        'Datenaustausch von Dokumenten mit der Lohnbuchhaltung',
        'Geführte Dokumentenablage seitens der Lohnmühle'
      ],
      price: 'inklusive',
      nr: 3
    },
    {
      title: 'Meldungen an Sozialvers. Träger',
      description: '',
      price: 'inklusive',
      nr: 4
    },
    {
      title: 'Mutterschutzfristen & Elternzeiten',
      description:
        'Berechnung und Überwachung von Mutterschutzfristen und Elternzeiten sowie Zuschüsse zum Mutterschaftsgeld',
      price: 'inklusive',
      nr: 5
    },
    {
      title: 'Anmeldung neuer Mitarbeiter',
      description: [
        'Sofortmeldung',
        'Anmeldung von neuen Mitarbeitern bei den Versicherungsträgern',
        'Einfache Lohnsteuer- und sozialversicherungsrechtliche Fragen',
        'Erfassung von neuen Arbeitnehmerstammdaten über edcloud und durch die Bereitstellung von edtime',
        'Archivierung gemäß GoBD'
      ],
      price: 'inklusive',
      sub: [
        {
          title: 'Abmeldung von Mitarbeitern',
          description: [
            'Abschließen aller lohnrelevanten Konten',
            'Abmeldung bei den Versicherungsträgern',
            'Bereitstellung von allen Dokumenten bezüglich Arbeitnehmer in der edcloud',
            'Archivierung gemäß GoBD'
          ],
          price: 'inklusive'
        }
      ],
      nr: 6
    },
    {
      title: 'Berufsgenossenschaftsmeldung',
      description: 'Aufbereitung, Erstellung und Meldungen',
      price: 'inklusive',
      nr: 7
    },
    {
      title: 'Jahresabschluss',
      description: [
        'Erstellung von Lohnsteuerjahresausgleichen',
        'Erstellung der Jahresendmeldungen (DEÜV) und Lohnsteuerbescheinigungen',
        'Archivierung gemäß GoBD',
        'Abgleich der Lohnkonten',
        'Erstellung von Jahresjournalen',
        'Alle relevanten Dokumente werden immer in edcloud abgelegt'
      ],
      price: 'inklusive',
      nr: 8
    },
    {
      title: 'Zahlungen',
      description:
        'Erstellung von Zahlungsaufträgen (Auszahlungsliste, SEPA, Bankenclearing)',
      price: 'inklusive',
      nr: 9
    },
    {
      title: 'Meldewesen',
      onlyTitle: true
    },
    {
      title: 'Sofortmeldungen',
      description: '',
      price: 'inklusive',
      nr: 10
    },
    {
      title: 'Lohnfortzahlung U1 im Krankheitsfall',
      description: '',
      price: 'inklusive',
      nr: 11
    },
    {
      title: 'Lohnfortzahlung U2 bei Mutterschaft/Beschäftigungsverbot',
      description: '',
      price: 'inklusive',
      nr: 12
    },
    {
      title: 'LSt.-Bescheinigungen, elektronisch',
      description: '',
      price: 'inklusive',
      nr: 13
    },
    {
      title: 'Mindestlaufzeiten und Kündigungsfristen',
      onlyTitle: true
    },
    {
      title: 'Vertragslaufzeit',
      description: 'monatlich kündbar',
      price: '',
      nr: 14
    },
    {
      title: 'Kündigungsfrist',
      description: 'monatlich kündbar',
      price: '',
      nr: 15
    }
  ];
  const zusatzkosten = [
    {
      title: 'Digitale Übernahme der Arbeitnehmer aus Vorsystem',
      description: 'einmalig bei Start',
      price: '8,50€ je Mitarbeiter',
      nr: 1
    },
    {
      title:
        'Digitaler Zugriff der Lohnabrechnung und Lohnsteuerbescheinigung für den Mitarbeiter',
      description: 'eMitarbeiter',
      price: '0,70€ je Mitarbeiter',
      nr: 2
    },
    {
      title: 'Wiederholungsabrechnung',
      description: 'ohne Eigenverschuldung der Lohnmühle',
      price: '15,31€ je Mitarbeiter',
      nr: 3
    },
    {
      title: 'Korrekturabrechung',
      description: 'ohne Eigenverschuldung der Lohnmühle',
      price: '7,65€ je Mitarbeiter',
      nr: 4
    },
    {
      title: 'Elektronische Arbeitsunfähigkeitsmeldung',
      description:
        'die eAU gilt nur für gesetzlich versicherte Arbeitnehmer. Wir rufen, aufgrund Ihrer Information, wann der Mitarbeiter krank geworden ist, die eAU bei der Krankenkasse ab und verwalten diese. Eine eAU Abfrage via edtime ist für Sie kostenfrei.',
      price: '6,50€',
      nr: 5
    },
    {
      title: 'Kurzarbeit',
      description: [
        'Unterstützung bei Erstanträgen',
        'Unterstützung bei den Folgeanträgen',
        'Einspielen der KUG Stunden aus edtime und edcloud',
        'Leistungssatz kontrollieren und Prozentsatz berechnen',
        'Bezugsmonat pflegen'
      ],
      price: '4,00€ je Abrechnungsfall',
      nr: 6
    },
    {
      title: 'Datenträger',
      description: [
        'Bereitstellung von Datenträgern bei Betriebsprüfungen',
        'Bereitstellung von Datenträgern bei Auftrags-/ Vertragsbeendigung, Geschäftsaufgabe, Insolvenz, etc.'
      ],
      price: '35,00€ je Datenträger',
      nr: 7
    },
    {
      title: 'Beratungs- und Sonderleistungen',
      description: '',
      price: '65,00€ pro Stunde / in 15 Minuten Taktung abgerechnet',
      nr: 8
    },
    {
      title: 'Bescheinigungen/Vorlagen für die Bundesagentur für Arbeit',
      onlyTitle: true
    },
    {
      title: 'Arbeitsbescheinigung gemäß §312 SGB (3)',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 9
    },
    {
      title: 'Bescheinigung über Nebeneinkommen nach §313 SGB (3)',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 10
    },
    {
      title:
        'Einkommensbescheinigung, Nachweis über die Höhe des Arbeitsentgelts',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 11
    },
    {
      title:
        'Arbeitsbescheinigung für Zwecke des über- und zwischenstaatlichen Rechts nach § 312a SGB |||',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 12
    },
    {
      title: 'Bescheinigungen/Vorlagen für die Krankenkasse',
      onlyTitle: true
    },
    {
      title: 'Entgeltbescheinigung Höhe der beitragspflichtigen Einnahme',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 13
    },
    {
      title: 'Entgeltbescheinigung KV bei Kinderkrankengeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 14
    },
    {
      title: 'Entgeltbescheinigung bei Wechsel der meldenden Stelle',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 15
    },
    {
      title: 'Entgeltbescheinigung KV bei Krankengeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 16
    },
    {
      title: 'Entgeltbescheinigung BA Übergangsgeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 17
    },
    {
      title: 'Entgeltbescheinigung KV bei Mutterschaftsgeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 18
    },
    {
      title: 'Entgeltbescheinigung RV bei Übergangsgeld Reha',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 19
    },
    {
      title: 'Entgeltbescheinigung RV bei Übergangsgeld Teilhabe',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 20
    },
    {
      title: 'Entgeltbescheinigung UV bei Kinderverletztegeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 21
    },
    {
      title: 'Entgeltbescheinigung UV bei Verletztengeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 22
    },
    {
      title: 'Entgeltbescheinigung UV bei Übergangsgeld',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 23
    },
    {
      title: 'Bescheinigungen/Vorlagen für die öffentliche Verwaltung',
      onlyTitle: true
    },
    {
      title: 'Verdienstbescheinigung zum Wohngeldantrag',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 24
    },
    {
      title: 'Bescheinigungen/Vorlagen für Firmen',
      onlyTitle: true
    },
    {
      title: 'Erklärung zur Übermittlung von Steuerdaten (StDÜV)',
      description: '',
      price: '25,00€ pro Bescheinigung',
      nr: 25
    },
    {
      title: 'Bescheinigungen/Vorlagen',
      onlyTitle: true
    },
    {
      title: 'A1 Ausnahmevereinbarung',
      description: 'Keine leitenden Angstellten und Geschäftsführer.',
      price: '17,00€ pro Bescheinigung',
      nr: 26
    },
    {
      title:
        'A1 Ausnahmevereinbarung für leitende Angstellte und Geschäftsführer.',
      description: '',
      price: '25,00€ pro Entsendung',
      nr: 27
    },
    {
      title: 'A1 Entsendebescheinigung',
      description: '',
      price: '17,00€ pro Bescheinigung',
      nr: 28
    },
    {
      title: 'Allgemein',
      onlyTitle: true
    },
    {
      title: 'Allgemeine Verdienstbescheinigungen',
      description: '',
      price: '25,00€ pro Bescheinigung',
      nr: 29
    },
    {
      title: 'Zuschussanträge der zuständigen Institutionen für Soforthilfe',
      description: [
        'Corona Antrag (Soforthilfe)',
        'Zusatzqualifikationen für die Finanzbuchhaltung und Steuerberater',
        'Corona Unterstützung für Studenten'
      ],
      price: '100,00€ pauschal',
      nr: 30
    },
    {
      title: 'Einmalig pro Pfändungsvorgang',
      description:
        'Prüfung und Berechnung von Pfändungen und Abtretungen (z.B Unterhaltspfändungen)',
      price: '25,00€ pro Bescheinigung',
      nr: 31
    },
    {
      title: 'Manuelle Erfassung von Bescheinigungen / SV-Meldungen',
      description: 'z.B 57 Meldungen',
      price: '25,00€ pro Bescheinigung',
      nr: 32
    },
    {
      title: 'Textfeld bei Lohnabrechung',
      description:
        'Individuelle Notizen, die auf der Lohnabrechnung für die Mitarbeiter sichtbar sind',
      price: '1,00€ pro Lohnabrechnung',
      nr: 33
    },
    {
      title: 'Quarantäneanträge',
      description: '',
      price: '50,00€ pro Bescheinigung',
      nr: 34
    },
    {
      title: 'BAföG - Antrag für Studenten',
      description: '',
      price: '50,00€ pro Bescheinigung',
      nr: 35
    }
  ];
  const goToNext = (e) => {
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;
    if (e.target.dataset.target) {
      const target = e.target.dataset.target;
      document
        .querySelector('[data-target=' + target + ']')
        .classList.add('active');
      anime({
        targets: scrollElement,
        easing: 'easeInOutQuad',
        scrollTop: document.getElementById(target).offsetTop - 100,
        duration: 1500
      });
    }
  };

  useEffect(() => {
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;
    scrollElement.scrollTop = 0;

    document.removeEventListener('scroll', activeOnScroll);
    document.removeEventListener('scroll', setActive);
    document.addEventListener('scroll', scrollToTop);
  }, []);

  return (
    <section id='specifications'>
      <div className='hero'>
        <img src={logo} alt='Lohnmühle' />
        <h1>Lohnmühle</h1>
        <h3>So geht Lohn heute</h3>
        <p>Leistungsverzeichnis</p>
      </div>
      <div className='fh'>
        <h2>Inhaltsverzeichnis</h2>
        <ul>
          <li
            data-target='system'
            className='list-title'
            onClick={(e) => goToNext(e)}
          >
            Systemlandschaft
          </li>
          <li
            data-target='leistungen'
            className='list-title'
            onClick={(e) => goToNext(e)}
          >
            Leistungen
          </li>
          <li className='submenu'>
            <ul>
              <li data-target='service' onClick={(e) => goToNext(e)}>
                Betreuung und Service
              </li>
              <li data-target='auswertungen' onClick={(e) => goToNext(e)}>
                Bereitstellung von Auswertungen
              </li>
              <li data-target='leistung' onClick={(e) => goToNext(e)}>
                Leistungen unserer Lohnbuchhaltung - inklusive
              </li>
              <li data-target='leistung-ex' onClick={(e) => goToNext(e)}>
                Leistungen unserer Lohnbuchhaltung - exklusive
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div id='system'>
        <h2>Systemlandschaft</h2>
        <p>Lohnbuchhaltung mit edlohn, edcloud und der Mitarbeiterakte.</p>
        <p>
          Aufgrund der stetig wachsenden Anforderungen an einen digitalen
          Datenaustausch zwischen Unternehmer und den Versicherungsträgern hat
          es sich die Lohnmühle zur Aufgabe gemacht, diese steigende
          Herausforderung zu bezwingen. Daher vertraut die Lohnmühle auf die
          Cloudlösungen der eurodata AG mit einem angeschlossenen Rechenzentrum
          in Saarbrücken. Weitere Informationen finden Sie unter:{' '}
          <a href='https://www.eurodata.de' target='blank'>
            www.eurodata.de
          </a>
        </p>
        <p>
          Jedes Lohnmandat wird im zertifizierten Hochleistungsrechenzentrum der
          eurodata AG gesichert und entsprechend der Grundsätze zur
          ordnungsgemäßen Führung und Aufbewahrung von Unterlagen in
          elektronischer Form sowie zum Datenzugriff (GoBD) archiviert.
        </p>
        <table>
          <thead>
            <tr>
              <th>Leistungsart</th>
              <th>Leistungstabelle</th>
              <th>Preise</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Systeme</td>
              <td>Nutzung der Lohnsoftware edlohn (ITSG-zertifiziert)</td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td></td>
              <td>Bereitstellung des Unternehmerportals edcloud</td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td></td>
              <td>Bereitstellung der eLohnakte</td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td></td>
              <td>Bereitstellung der Unternehmersoftware edtime</td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td></td>
              <td>
                Optional: Bereitstellung des e-Mitarbeiters (Zusatzkosten)
              </td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td>Speicherung</td>
              <td>
                Datenspeicherung gemäß AGB: Archivierung und Datenzugriff
                sämtlicher Unterlagen entsprechend der Grundsätze zur
                ordnungsmäßigen Führung und Aufbewahrung von Unterlagen in
                elektronischer Form
              </td>
              <td>siehe Vereinbarung</td>
            </tr>
            <tr>
              <td>Rechenzentrum</td>
              <td>
                Hochleistungsrechenzentrum in Saarbrücken (zertifiziert ISO/
                IEC27001)
              </td>
              <td>inklusive</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id='leistungen'>
        <h2>Leistungen</h2>
        <div id='service'>
          <h3>Betreuung & Service</h3>
          <p>
            Damit wir für Sie zuverlässig und zügig Ihre Lohnabrechnung
            bereitstellen können, haben wir eine separate Kundenhilfe, die Ihnen
            mit Rat und Tat zur Seite steht.
          </p>
          <table className='center'>
            <tbody>
              <tr>
                <td colSpan='3'>
                  <AccessTime /> Servicezeiten
                </td>
              </tr>
              <tr>
                <td className='bold'>Kundenhilfe & Betreuung</td>
                <td className='bold'>Montag - Freitag</td>
                <td className='bold'>09:00 - 17:00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id='auswertungen'>
          <h3>Bereitstellung von Auswertungen</h3>
          <p>
            Die Lohnmühle stellt dem Unternehmer alle lohnrelevanten Daten in
            der edcloud digital zur Verfügung. Zusätzlich kann der Unternehmer
            bei Bedarf den Mitarbeiter für den e-Mitarbeiter freischalten
            lassen, damit dieser einen direkten Zugriff auf sein Entgelt hat.
            (weitere Details sowie Preise siehe Produktblatt).
          </p>
        </div>
        <div id='leistung'>
          <h3>Leistungen unserer Lohnbuchhaltung - inklusive</h3>
          <table>
            <thead>
              <tr>
                <th className='number'>Nr.</th>
                <th></th>
                <th>Leistungsumfang</th>
                <th>Kommentar/Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              {_.map(leistung, (li, index) => {
                return (
                  <tr key={index}>
                    <td></td>
                    <td>
                      {li.title}
                      {li.title === 'edcloud (cloud basierte Archivierung)' && (
                        <ul>
                          <li>inkl. eLohnakte </li>
                          <li>inkl. Firmenakte</li>
                          <li>inkl. Pendelorder</li>
                        </ul>
                      )}
                    </td>
                    <td
                      style={{
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      {li.price.indexOf('€') < 0 ? (
                        li.price
                      ) : (
                        <span className='require'>{li.price}</span>
                      )}
                    </td>
                    <td>
                      {!_.isArray(li.description) ? (
                        li.title === 'edcloud (cloud basierte Archivierung)' ? (
                          <div>
                            {li.description}
                            <ul style={{ marginBottom: 0 }}>
                              <li>
                                Bereitstellung von Standardlohnauswertungen und
                                Buchungsdateien über edcloud
                              </li>
                            </ul>
                            (Erläuterung: Preis pro Abrechnung = Jeder
                            angemeldete Mitarbeiter)
                          </div>
                        ) : (
                          li.description
                        )
                      ) : (
                        <ul>
                          {_.map(li.description, (desc, i) => (
                            <li key={i}>{desc}</li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr className='title'>
                <td colSpan='4'>Leistungsumfang</td>
              </tr>
              {_.map(leistungumfang, (umfang, index) => {
                if (umfang.onlyTitle) {
                  return (
                    <tr className='title' key={index}>
                      <td colSpan='4'>{umfang.title}</td>
                    </tr>
                  );
                }
                return (
                  <>
                    <tr key={index}>
                      <td className='number'>{umfang.nr}</td>
                      <td>{umfang.title}</td>
                      <td className='bold' style={{ textAlign: 'center' }}>
                        {umfang.price.indexOf('€') < 0 ? (
                          umfang.price
                        ) : (
                          <span className='require'>{umfang.price}</span>
                        )}
                      </td>
                      <td>
                        {!_.isArray(umfang.description) ? (
                          umfang.description
                        ) : (
                          <ul>
                            {_.map(umfang.description, (desc, i) => (
                              <li key={i}>{desc}</li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                    {umfang.sub &&
                      _.map(umfang.sub, (sub, idx) => {
                        return (
                          <tr key={idx}>
                            <td className='number'>
                              {index + 1}.{idx + 1}
                            </td>
                            <td>{sub.title}</td>
                            <td
                              className='bold'
                              style={{ textAlign: 'center' }}
                            >
                              {sub.price.indexOf('€') < 0 ? (
                                sub.price
                              ) : (
                                <span className='require'>{sub.price}</span>
                              )}
                            </td>
                            <td>
                              {!_.isArray(sub.description) ? (
                                sub.description
                              ) : (
                                <ul>
                                  {_.map(sub.description, (desc, i) => (
                                    <li key={i}>
                                      {desc ===
                                      'Eintragung der Kurzarbeiterstunden durch Sie in edtime'
                                        ? 'Eintragung der' +
                                          <br /> +
                                          'Kurzarbeiterstunden' +
                                          <br /> +
                                          'durch Sie in edtime'
                                        : desc}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
        <div id='leistung-ex'>
          <h3>Leistungen unserer Lohnbuchhaltung - exklusive</h3>
          <table>
            <thead>
              <tr>
                <th className='number'>Nr.</th>
                <th></th>
                <th>Leistungsumfang</th>
                <th>Kommentar/Beschreibung</th>
              </tr>
            </thead>
            <tbody>
              {_.map(zusatzkosten, (z, inx) => {
                if (z.onlyTitle) {
                  return (
                    <tr className='title' key={inx}>
                      <td colSpan='4'>{z.title}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={inx}>
                    <td className='number'>{z.nr}</td>
                    <td>{z.title}</td>
                    <td className='bold' style={{ textAlign: 'center' }}>
                      {z.title === 'Kurzarbeit' ? (
                        <div className='flex-box column'>
                          <span>
                            {
                              z.price
                                .replace('€', `€*`)
                                .replace('&euro', '€*')
                                .split('*')[0]
                            }
                            <span className='require' />
                            {
                              z.price
                                .replace('€', `€*`)
                                .replace('&euro', '€*')
                                .split('*')[1]
                            }
                          </span>
                        </div>
                      ) : z.price.indexOf('€') < 0 ? (
                        z.price
                      ) : (
                        <span>
                          {
                            z.price
                              .replace('€', `€*`)
                              .replace('&euro', '€*')
                              .split('*')[0]
                          }
                          <span className='require' />
                          {
                            z.price
                              .replace('€', `€*`)
                              .replace('&euro', '€*')
                              .split('*')[1]
                          }
                        </span>
                      )}
                    </td>
                    <td>
                      {!_.isArray(z.description) ? (
                        z.description
                      ) : (
                        <ul>
                          {_.map(z.description, (desc, i) => (
                            <li
                              key={i}
                              className={classnames({
                                italic:
                                  desc ===
                                  '(Kosten einer Antragsstellung, wenn mehrere Betriebsstätten zusammengeführt werden müssen)'
                              })}
                            >
                              {desc}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className='text-right text-bold'>
            <span className='text-red'>*</span> Preise zuzüglich Mehrwertsteuer
          </p>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Specifications);
