import React, { useEffect } from 'react';

const Impressum = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section id="impressum">
      <h1>Impressum</h1>
      <h2>Anbieter i.S.d. § 5 TMG ist:</h2>
      <p>
        Lohnmühle GmbH
        <br />
        vertreten durch den Geschäftsführer Zeljko Katavic
        <br />
        Kesselstraße 19
        <br />
        D-70327 Stuttgart
        <br />
        Telefon: 0711/65 66 90 83
        <br />
        E-Mail-Adresse: info@lohnmuehle.de
      </p>
      <p>Registergericht: AG Stuttgart HRB 774 127</p>
      <p>
        Umsatzsteuer-ID: <span className="bold">DE335213000</span>
      </p>
      <h3>Informationen zur Verbraucherstreitschlichtung:</h3>
      <p>
        Zur Teilnahme an einem Streitbeilegungsverfahren nach dem
        Verbraucherstreitbeilegungsgesetz (VSBG) sind wir nicht
        verpflichtet und nicht bereit.
      </p>
      <h3>Haftung für Inhalte:</h3>
      <p>
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
        Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
        verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
        gespeicherte fremde Informationen zu überwachen oder nach
        Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
        Nutzung von Informationen nach den allgemeinen Gesetzen
        bleiben hiervon unberührt.
      </p>
      <h3>Haftung für Links:</h3>
      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf
        deren Inhalte wir keinen Einfluss haben. Deshalb können wir
        für diese fremden Inhalte auch keine Gewähr übernehmen. Für
        die Inhalte der verlinkten Seiten ist stets der jeweilige
        Anbieter oder Betreiber der Seiten verantwortlich. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige
        Links umgehend entfernen.
      </p>
    </section>
  );
};

export default Impressum;
