import DoctorIcon from '../assets/icons/Doctor.png';
import ProductionIcon from '../assets/icons/Production.png';
import SkiIcon from '../assets/icons/Ski.png';
import GardenIcon from '../assets/icons/Garden.png';
import CleanIcon from '../assets/icons/Clean.png';
import EinzelhandelIcon from '../assets/icons/Einzelhandel.png';
import RepairIcon from '../assets/icons/_repair.png';
import TextilIcon from '../assets/icons/Textil.png';
import HotelIcon from '../assets/icons/Hotel.png';
import RestaurantIcon from '../assets/icons/Restaurant.png';
import DrywallIcon from '../assets/icons/_drywall.png';
import CosmeticIcon from '../assets/icons/Cosmetic.png';
import SafeIcon from '../assets/icons/save-company.png';
import InsuranceIcon from '../assets/icons/_insurance-broker.png';
import BaeckerIcon from '../assets/icons/Baecker.png';

export const branchesArr = [
    {
        name: 'Ärzte',
        icon: DoctorIcon
    },
    {
        name: 'Produktionsunternehmen',
        icon: ProductionIcon
    },
    {
        name: 'Skilifte',
        icon: SkiIcon
    },
    {
        name: 'Garten und Landschaftsbau',
        icon: GardenIcon
    },
    {
        name: 'Reinigungsunternehmen',
        icon: CleanIcon
    },
    {
        name: 'Groß- und Einzelhandel',
        icon: EinzelhandelIcon
    },
    {
        name: 'Reparatur- und Wartungsservice',
        icon: RepairIcon
    },
    {
        name: 'Textilpflege',
        icon: TextilIcon
    },
    {
        name: 'Hotels',
        icon: HotelIcon
    },
    {
        name: 'Restaurants',
        icon: RestaurantIcon
    },
    {
        name: 'Trockenbau',
        icon: DrywallIcon
    },
    {
        name: 'Kosmetik und Friseure',
        icon: CosmeticIcon
    },
    {
        name: 'Sicherheitsunternehmen',
        icon: SafeIcon
    },
    {
        name: 'Versicherungsmakler',
        icon: InsuranceIcon
    },
    {
        name: 'Bäcker',
        icon: BaeckerIcon
    },
]